<template>
  <div class="me-wrapper">

    <div class="my-face">
      <div>
        <div class="_face">
          <img src="../status/img/me_face.png" />
        </div>
      </div>
      <div>
        <div class="_name">{{ userInfo.nick_name }}</div>
        <div class="_points"><span><b>积点</b><i>{{ userInfo.integral }}</i></span></div>
      </div>
    </div>

    <div class="_img my-line">
      <img src="../status/img/me_line.png" />
    </div>
    <div class="_img my-btn">
      <router-link to="/me/list"><img src="../status/img/me_btn_1.png" /></router-link>
    </div>
    <div class="_img my-btn">
      <img src="../status/img/me_btn_2.png" @click="onClickLogout" />
    </div>
    <div class="_img my-bg">
      <img src="../status/img/my_bg.png" />
    </div>

    <Footer :step="3" />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import Footer from './components/footer'

export default {
  mixins: [],
  components: {Footer},
  data() {
    return {
      userInfo:{}
    };
  },
  created() {
  },

  mounted() {
    this.getMyInfo();
  },

  methods: {

    onClickLogout() {
      Cookies.remove('admin_hdbank_test_token');
      this.$router.push("/login")
    },
    async getMyInfo() {
      // 获取分类列表
      let {data,code } = await this.$ajax.gateway("/api/api_get_my_info", {
        "METHOD": "get"
      });
      if(code!=200){
        Cookies.remove('admin_hdbank_test_token');
        this.$router.push("/login")
      }else{
        this.userInfo=data;
      }
    },
  }
};
</script>

<style lang="less" scoped>
@import "../status/css/page.less";

.my-face {
  padding: 2rem 0 1.2rem;
  text-align: center;
  ._face {
    img {
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 100%;
      border: 3px solid #c3ab81;
    }
  }
  ._name {
    color: #c8b489;
    padding-top: 0.1rem;
    font-size: 0.45rem;
  }
  ._points {
    padding-top: 0.1rem;
    span {
      display: inline-block;
      line-height: 0.5rem;
      padding: 0.05rem;
      background: #c8b489;
      color: #af1c1f;
      border-radius: 0.25rem;
      font-size: 0.3rem;
      b {
        display: inline-block;
        background: #af1c1f;
        color: #c8b489;
        padding: 0 0.2rem;
        border-radius: 0.25rem 0 0 0.25rem;
      }
      i {
        display: inline-block;
        font-style: normal;
        padding: 0 0.1rem;
        min-width: 1.2rem;
      }
    }
  }
}
.my-line {
  margin-bottom: 0.3rem;
}
.my-btn {
  margin-bottom: 0.6rem;
}
.my-bg {
  padding-top: 0.5rem;
}
</style>
